import * as React from 'react';

function SvgESign(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.563.799a1.02 1.02 0 011.443 0l1.695 1.756a1.02 1.02 0 010 1.443l-1.756 1.756-3.138-3.2L10.563.8zM8.109 3.253l3.138 3.2-5.754 5.754a1.02 1.02 0 01-.522.279l-3.666 1.009a.255.255 0 01-.3-.3l1.07-3.666c.04-.198.137-.38.28-.522l5.754-5.754z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.045 13.93a3.842 3.842 0 01-1.11.854c-2.49 1.303-6.081.564-7.435-.144a.513.513 0 01-.215-.674.47.47 0 01.644-.224c1.207.63 4.46 1.251 6.576.144.308-.161.556-.348.753-.55a5.469 5.469 0 01-.079-.072c-.551-.518-.986-1.142-1.177-1.745a1.915 1.915 0 01-.077-.946 1.28 1.28 0 01.529-.817c.254-.177.532-.266.816-.255.282.011.536.12.747.283.414.32.688.856.794 1.434a3.207 3.207 0 01-.228 1.88c.842.506 1.838.704 2.74.277a.472.472 0 01.634.251c.11.253.002.55-.24.664-1.29.611-2.627.286-3.672-.36zm-.258-1.445c.128-.376.141-.753.082-1.077-.074-.4-.25-.683-.421-.815a.377.377 0 00-.215-.089.402.402 0 00-.247.088.274.274 0 00-.121.185c-.018.091-.013.232.048.426.12.38.424.85.874 1.282z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgESign;
